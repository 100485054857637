<template>
  <div class="vx-row mb-6">
    <div class="vx-col w-full">
      <label class="vs-input--label">Price Proposal</label>
      <br />
      <ul class="centerx">
        <li>
          <vs-radio v-model="mutableValue" vs-value="Disallow"
            >Disallow</vs-radio
          >
        </li>
        <li>
          <vs-radio v-model="mutableValue" vs-value="Allow">Allow</vs-radio>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      mutableValue: "Disallow",
    };
  },
  mounted() {
    if (this.value) {
      this.mutableValue = this.value;
    }
  },
  watch: {
    mutableValue(val) {
      this.$emit("data", val);
    },
    value(val) {
      this.mutableValue = val;
    },
  },
};
</script>