<template>
  <vx-card :title="title">
    <vs-tabs>
      <vs-tab label="Sales Channel">
        <div class="tab-text">
          <sales-channel></sales-channel>
        </div>
      </vs-tab>
      <vs-tab label="Sales Channel Group">
        <div class="tab-text">
          <sales-channel-group></sales-channel-group>
        </div>
      </vs-tab>
      <vs-tab label="Commission">
        <div class="tab-text">
          <commission></commission>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Commission from "./commission";
import SalesChannel from "./sales-channel";
import SalesChannelGroup from "./sales-channel-group";
export default {
  components: {
    Commission,
    SalesChannel,
    SalesChannelGroup
  },
  data() {
    return {
      title: "Sales Channel"
    };
  }
};
</script>