<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
        <span>
            <h4 class="mb-5" v-if="commissionId!=null">Edit Commission</h4>
            <h4 class="mb-5" v-else>Create Commission</h4>
        </span>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Code" name="Code" v-model="data.code" readonly/>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input v-validate="'required'" class="w-full" label="Name" name="Name" v-model="data.name" />
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('Name')"
                    >{{ errors.first('Name') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-textarea class="w-full" label="Notes" name="Notes" v-model="data.notes" />
                <!-- <vs-input class="w-full" label="Notes" name="Notes" v-model="data.notes" /> -->
            </div>
        </div>
        <div class="vx-row mb-6" style="display: flex">
            <div class="vx-col w-1/2" style="display: flex">
                <!-- <vs-input class="w-full" label="Model" name="Model" v-model="data.model" /> -->
                <label class="vs-input--label">Model</label>
                <ul class="ml-6">
                    <li>
                        <vs-radio name="model" v-validate="'required'" v-model="data.model" vs-value="Final">Final</vs-radio>
                    </li>
                    <li>
                        <vs-radio name="model" v-validate="'required'" v-model="data.model" vs-value="Step Ladder">Step Ladder</vs-radio>
                    </li>
                    <span
                        class="text-danger text-sm"
                        v-show="errors.has('model')"
                        >{{ errors.first('model') }}
                    </span>
                </ul>
            </div>
            <div class="vx-col w-1/2" style="display: flex">
                <!-- <vs-input class="w-full" label="Unit" name="Unit" v-model="data.unit" /> -->
                <label class="vs-input--label">Period</label>
                <ul class="ml-6">
                    <li>
                        <vs-radio name="period" v-validate="'required'" v-model="data.period" vs-value="Monthly">Monthly</vs-radio>
                    </li>
                    <li>
                        <vs-radio name="period" v-validate="'required'" v-model="data.period" vs-value="Annually">Annually</vs-radio>
                    </li>
                    <span
                        class="text-danger text-sm"
                        v-show="errors.has('period')"
                        >{{ errors.first('period') }}
                    </span>
                </ul>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Valid From</label>
                <datepicker name="valid from" v-validate="'required'" :inline="false" v-model="data.valid_from" placeholder="Select Date"></datepicker>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('valid from')"
                    >{{ errors.first('valid from') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Valid To</label>
                <datepicker name="valid to" v-validate="'required'" :inline="false" v-model="data.valid_to" placeholder="Select Date"></datepicker>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('valid to')"
                    >{{ errors.first('valid to') }}
                </span>
            </div>
        </div>
        <vs-table 
            stripe
            border
            description 
            :sst="true"
            :data="steps"
            class="vx-row mb-6 ml-1 mr-1"
        >
            <template slot="thead">
                <vs-th>Step</vs-th>
                <vs-th>From</vs-th>
                <vs-th>To</vs-th>
                <vs-th width="10%">Discount (%)</vs-th>
                <vs-th></vs-th>
            </template>
            <template :data="steps">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in steps">
                    <vs-td>{{tr.step}}</vs-td>
                    <vs-td>
                        <vs-input @input="handleStep(indextr, 0)" v-model="tr.from" min="0" class="w-full"/>
                    </vs-td>
                    <vs-td>
                        <vs-input @input="handleStep(indextr, 1)" v-model="tr.to" min="0" class="w-full"/>
                    </vs-td>
                    <vs-td>
                        <vs-input v-model.number="tr.discount" class="w-full"/>
                    </vs-td>
                    <vs-td>
                        <vs-button
                            v-if="steps[indextr].button=='minus'"
                            style="display: table-cell;"
                            v-on:click="handleRemoveItem(indextr)"
                            color="danger"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-minus"
                        ></vs-button>
                        <vs-button
                            v-else
                            style="display: table-cell;"
                            v-on:click="handleAddItem()"
                            color="success"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-plus"
                        ></vs-button>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                 <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        Datepicker
    },
    props: {
        commissionId: {
            type: Number
        }
    },
    created() {
        // if(this.commissionId == null){
            this.getCode();
        // }
    },
    data() {
        return this.initialState();
    },
    methods: {
        handleAddItem(){
            var counter = this.steps.length
            this.steps.push({
                step: counter+1,
                from: 0,
                to: 0,
                discount: 0,
                button: "minus"
            })
        },
        handleRemoveItem(indextr){
            this.steps.splice(indextr, 1)
            for (var i = indextr; i < this.steps.length; i++) {
                this.steps[i]["step"] -= 1
            } 
        },
        initialState() {
            return {
                steps: [
                    {
                        step: 1,
                        from: 0,
                        to: 0,
                        discount: 0
                    }
                ],
                data: {
                    code: "",
                    name: "",
                    notes: "",
                    model: "",
                    period: "",
                    valid_from: "",
                    valid_to: ""
                }
            };
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                // if (this.data.name.length == "") {
                //     this.errors.add({
                //         field: "name",
                //         msg: "The name is required"
                //     });
                //     result = false;
                // }
                if (result) {
                    this.$vs.loading();
                    var _this = this
                    this.steps.forEach(function(element, index){
                        _this.steps[index].from = parseInt(element.from.replace(/\./g, ""))
                        _this.steps[index].to = parseInt(element.to.replace(/\./g, ""))
                    })

                    if (this.commissionId) {
                        console.log(this.paramData())
                        this.putData();
                    } else {
                        this.postData();
                        console.log(this.paramData())
                    }
                }
            });
        },
        handleStep(index, field) {
            if (field == 0){
                this.steps[index].from = this.steps[index].from.replace(/\./g, "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                this.steps[index].to = this.steps[index].to.replace(/\./g, "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
            
        },
        paramData() {
            return {
                commission_id: this.commissionId,
                code: this.data.code,
                name: this.data.name,
                notes: this.data.notes,
                model: this.data.model,
                period: this.data.period,
                valid_from: this.data.valid_from,
                valid_to: this.data.valid_to,
                steps: this.steps
            };
        },
        postData() {
            this.$http
                .post("/api/v1/master/commission", this.paramData())
                .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "New Commission Created",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getCode() {
            this.$vs.loading();
            if (!this.commissionId) {
                this.$http
                .get("/api/v1/setting/master-number-code/CM")
                .then((resp) => {
                if (resp.status == "success") {
                    this.data.code = resp.data;
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
                });
                console.log(this.data.code)
                if (this.data.code == ""){
                    this.$http
                    .get("/api/v1/master/commission/code")
                    .then(resp => {
                        if (resp.code == 200) {
                            this.data.code = resp.data
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle"
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            }
        },
        putData() {
        this.$http
            .put("/api/v1/master/commission/" + this.commissionId, this.paramData())
            .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "Commission Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        getData() {
        this.$vs.loading();
            if (this.commissionId) {
                this.$http
                .get("/api/v1/master/commission/" + this.commissionId)
                .then(resp => {
                    this.$vs.loading.close();
                    var _this = this
                    if (resp.code == 200) {
                        this.data.code = resp.data.code;
                        this.data.name = resp.data.name;
                        this.data.notes = resp.data.notes;
                        this.data.model = resp.data.model;
                        this.data.period = resp.data.period;
                        this.data.valid_from = resp.data.valid_from;
                        this.data.valid_to = resp.data.valid_to;
                        // this.steps = resp.data.steps;

                        resp.data.steps.forEach(function(element, index){
                            var btn = "minus"
                            if(index == 0){
                                btn = "plus"
                            }
                            _this.steps.push({
                                from: element.from.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
                                to: element.to.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
                                step: element.step,
                                discount: element.discount,
                                button: btn
                            })
                        })
                        this.steps.shift();
                       
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
            }
            this.$vs.loading.close();
        },
        
    },
    mounted() {
        if (this.commissionId) {
            this.getData();
        }
    },
    computed: {},
    watch: {
        commissionId() {
            Object.assign(this.$data, this.initialState());
            this.getData();
            this.getCode();
        },
        "data.name": function(val) {
            if (val == "") {
                this.errors.add({
                    field: "name",
                    msg: "The name field is required"
                });
            } else {
                this.errors.clear()
            }
        },
        // "steps.0.from": function(val) {
        //     val = val.toString().replace(/\./g, "");
        //     this.steps[0].from = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        //     console.log(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."))
        // },
        // "steps.0.to": function(val) {
        //     val = val.toString().replace(/\./g, "");
        //     this.steps[0].to = val
        //         .toString()
        //         .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        // }
    }
};
</script>