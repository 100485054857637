<template>
  <div class="vx-row mb-6">
    <div class="vx-col w-full">
      <label class="vs-input--label">Commission</label>
      <multiselect
        v-model="selected"
        :options="options"
        placeholder="Type to search"
        label="name"
        track-by="name"
        @search-change="handleSearch"
        :showNoOptions="false"
        :max-height="125"
        :allow-empty="false"
      >
        <span slot="noResult">Oops! No data found</span>
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
          </span>
        </template>

        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      baseUrl: "/api/v1/master/commission",
      selected: null,
      options: [],
      search: ""
    };
  },
  methods: {
    handleSearch(query) {
      if (query != "") {
        this.search = query;
        this.getData();
      }
    },
    getData() {
      this.$vs.loading();
      let param = {
        order: "name",
        sort: "asc"
      };
      this.$http
        .get(this.baseUrl + "s", {
          params: param
        })
        .then(resp => {
          this.$vs.loading.close();
          this.options = resp.data.records;
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataById() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.selected = resp.data;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    }
  },
  watch: {
    selected(v) {
      this.options = [];
      if (v) {
        this.options.push(v);
        this.$emit("data", v.id);
      } else {
        this.$emit("data", null);
      }
    },
    id(v) {
      if (v) {
        this.getDataById();
      } else {
        this.selected = null;
      }
    }
  },
  mounted() {},
  computed: {}
};
</script>