<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <sales-channel-group
      :id="data.salesChannelGroupId"
      @data="setSalesChannelId"
    ></sales-channel-group>
    <div class="vx-row mb-6" v-if="id != null">
      <div class="vx-col w-full">
        <vs-input disabled class="w-full" label="Code" v-model="data.code" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
          errors.first("Name")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Minimum SO Value"
          v-validate="'required'"
          name="minimum_order"
          v-model="data.minimum_order"
          @keypress="isNumber($event)"
          @input="handleStep()"
          @focus="focusMinOrder()"
          @blur="blurMinOrder()"
          min="0"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('minimum_order')"
          >{{ errors.first("minimum_order") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Type</label>
        <br />
        <vs-radio name="type" v-model="data.type" vs-value="Direct"
          >Direct</vs-radio
        >&nbsp;&nbsp;&nbsp;
        <vs-radio name="type" v-model="data.type" vs-value="In-direct"
          >In-direct</vs-radio
        >
      </div>
    </div>
    <business-partner
      @data="setBusinessPartnerId"
      :id="data.businessPartnerId"
    ></business-partner>
    <commission :id="data.commissionId" @data="setCommisionId"></commission>
    <invoice-model
      :value="data.invoiceModel"
      @data="setInvoiceModel"
    ></invoice-model>
    <delivery-model
      :value="data.deliveryModel"
      @data="setDeliveryModel"
    ></delivery-model>
    <price-proposal
      :value="data.priceProposal"
      @data="setPriceProposal"
    ></price-proposal>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import SalesChannelGroup from "./SalesChannelGroup";
import BusinessPartner from "./BusinessPartner";
import DeliveryModel from "./DeliveryModel";
import InvoiceModel from "./InvoiceModel";
import Commission from "./Commission";
import PriceProposal from "./PriceProposal";
export default {
  components: {
    SalesChannelGroup,
    BusinessPartner,
    DeliveryModel,
    InvoiceModel,
    Commission,
    PriceProposal,
  },
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          salesChannelGroupId: null,
          businessPartnerId: null,
          commissionId: null,
          code: "",
          name: "",
          type: "Direct",
          deliveryModel: "Delivery Business Partner",
          invoiceModel: "Invoice Business Partner",
          minimum_order: 0,
          priceProposal: "Disallow",
        },
      };
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleStep() {
      if (this.data.minimum_order.replace(/\./g, "").length < 16) {
        this.data.minimum_order = this.data.minimum_order
          .replace(/\./g, "")
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        this.data.minimum_order = "999.999.999.999.999";
      }
    },
    focusMinOrder() {
      if (this.data.minimum_order == 0) {
        this.data.minimum_order = "";
      }
    },
    blurMinOrder() {
      if (this.data.minimum_order == "") {
        this.data.minimum_order = 0;
      }
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          if (typeof this.data.minimum_order == "string") {
            this.data.minimum_order = parseInt(
              this.data.minimum_order.replace(/\./g, "")
            );
          }
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      var invoiceModel = "Business Partner";
      if (this.data.invoiceModel != "Invoice Business Partner") {
        invoiceModel = "End Customer";
      }

      var deliveryModel = "Business Partner";
      if (this.data.deliveryModel != "Delivery Business Partner") {
        deliveryModel = "End Customer";
      }
      return {
        sales_channel_group_id: this.data.salesChannelGroupId,
        business_partner_id: this.data.businessPartnerId,
        commission_id: this.data.commissionId,
        code: this.data.code,
        name: this.data.name,
        type: this.data.type,
        price_proposal: this.data.priceProposal,
        invoice_model: invoiceModel,
        delivery_model: deliveryModel,
        minimum_order: this.data.minimum_order,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.id;
            this.data.code = resp.data.code;
            this.data.name = resp.data.name;
            this.data.type = resp.data.type;
            this.data.invoiceModel = "Invoice " + resp.data.invoice_model;
            this.data.deliveryModel = "Delivery " + resp.data.delivery_model;
            this.data.salesChannelGroupId = resp.data.sales_channel_group_id;
            this.data.businessPartnerId = resp.data.business_partner_id;
            this.data.commissionId = resp.data.commission_id;
            this.data.priceProposal = resp.data.price_proposal;
            this.data.minimum_order = resp.data.minimum_order
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    setSalesChannelId(val) {
      this.data.salesChannelGroupId = val;
    },
    setCommisionId(val) {
      this.data.commissionId = val;
    },
    setBusinessPartnerId(val) {
      this.data.businessPartnerId = val;
    },
    setDeliveryModel(val) {
      this.data.deliveryModel = val;
    },
    setInvoiceModel(val) {
      this.data.invoiceModel = val;
    },
    setType(val) {
      this.data.type = val;
    },
    setPriceProposal(val) {
      console.log(val);
      this.data.priceProposal = val;
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
</style>
